<template>
  <div class="container-fluid px-0">
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">FAT Config</b-card-header>
      <b-card-body>
        <div class="text-max-width">
          Upload and download FAT configs and acceptance criteria
        </div>
        <b-row>
          <b-col>

            <div class="mt-3">
              <b-button variant="primary" @click="downloadConfig">Download</b-button>
              <br>
              <b-form-textarea plaintext rows=30 :value="current_file">
              </b-form-textarea>
            </div>
          </b-col>
          <b-col>
            <b-form-file v-model="file" :state="file ? true : null" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here...">

            </b-form-file>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
            <b-button variant="primary" @click="submitConfig">Submit</b-button>
            <div class="mt-3">
              <b-toast id="submit-message" title="Response" static no-auto-hide>{{ response }}</b-toast>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'FATUpload',
  data() {
    return {
      file: null,
      response: '',
      current_file: '',
    };
  },
  methods: {
    async submitConfig() {
      let res;
      try {
        res = this.file ? await this.$fetch(
          'https://api-fat-config.morgan.solar/config_upload/',
          { method: 'POST', body: JSON.parse(await this.file.text()), kauth: 'include' }
        ) : 'No file provided';
      } catch (error) {
        res = 'Request failed';
      }

      this.response = res;
      this.$bvToast.show('submit-message');
    },
    async downloadConfig() {
      let res;
      res = await this.$fetch('https://api-fat-config.morgan.solar/config_download/', { kauth: 'include' });
      res = JSON.stringify(JSON.parse(res), null, 2);
      this.current_file = res;
    },
  }
};
</script>

<style scoped></style>
